.carousel-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width:99vw;
    /* padding-bottom:100px; */
    z-index: 9999;
    height:650px
}
.tt{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

.carousel-img{
    /* width: 100%; */
    height: auto;
    max-width: 300px;
    min-width: 200px;
    z-index: 9999;
}

/* @media only screen and (max-width : 1919px) {
    .carousel-container{
        display: none;
    }
} */