.header-container{
    height:246px;
    width:100%;
    background-image: url("../images/header.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    justify-content: flex-end;
    align-items: center;
}
.connectWallet{
    font-size: 0.8em;
    border-radius: 5px;
    
    background-color: #ffcc00;
    /* background-image: url("../images/connect_bg.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border:none;
    color:#3b2774;
}
.connectWallet:enabled:hover{
    /* background-image: url("../images/connect_bg.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #ffcc00;
    color:#4f31a1;
    box-shadow: none;
}
.p-button-label {
    flex: 1 1 auto;
    font-weight: 600;
}

.header-info{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
    margin-right: 2vw;
}
.menu{
    display:flex;
    border-radius: 5px;
    margin-bottom: 130px;
    margin-right: 2vw;
}

.address{
    display:flex;
    text-align: center;
    font-weight: 600;
    padding-bottom: 10px;
}
.balance{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-weight: 500;
    gap:10px;
    padding-bottom: 10px;
}
.refresh{
    height:27px;
    background-color: #3b2774;
    color:#fff;
    border:0px solid #3b2774
}
.refresh:enabled:hover{
    background-color: #4f3499;
    color:#fff;
}
.refresh:focus{
    border:0px solid #3b2774;
    box-shadow: 0 0 0 0.0rem #3b2774
}
.blc{
    display:flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3b2774;
    border-radius: 5px;
    padding: 3px 5px;
    width: 110px;
    background-color: #3b2774;
    color:#fff;
}

.credit {
    display:flex;
    flex-direction: column;
    border: 1px solid #3b2774;
    border-radius: 5px;
    padding: 20px 10px;
    cursor: pointer;
}
.deposit{
    display:flex;
    flex-direction: column;
    border: 1px solid #3b2774;
    border-radius: 5px;
    padding: 20px 10px;
    cursor: pointer;
}
.deposit-row{
    display: flex;
    flex-direction: row;
    gap:5px;
    padding-top:10px
}
.top-title-funds{
    color:#ffcc00
}
.title-funds{
    padding-bottom: 15px;
}
.deposit-amount{
    margin-bottom: 20px;
}
.sidebar{
    height:100%
}
.big{
    font-size: 2em;
    padding-bottom: 10px;
}
.error{
    color:red;
    padding-left: 20px;
}
.shake{
    animation: shake 0.5s ease 0s 1 normal forwards;
}
@keyframes shake {
	0%,
	100% {
		transform: translateX(0);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateX(-10px);
	}

	20%,
	40%,
	60% {
		transform: translateX(10px);
	}

	80% {
		transform: translateX(8px);
	}

	90% {
		transform: translateX(-8px);
	}
}
@media screen and (max-width: 800px) {
    .header-info{
        display:none
    }
    .menu{
        display: flex;
    }
}